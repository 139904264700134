import React from 'react';

function Header() {
    return (
        <div className="flex w-screen h-16 justify-between ">
            <div className="flex text-6xl">Matt</div>

            <div className="flex w-72">
                <button className="flex-1 text-2xl">Home</button>
                <button className="flex-1 text-2xl">Item</button>
                <button className="flex-1 text-2xl">Item</button>
            </div>
        </div>
    );
}

function Content() {
    return (
        <div className="flex-col text-center px-16 md:px-32 lg:px-48">
            <div className="text-blue-500 text-3xl md:text-6xl p-16">
                Welcome
            </div>
            <div className="">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum
                dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
                sed diam voluptua. At vero eos et accusam et justo duo dolores
                et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus
                est Lorem ipsum dolor sit amet.
            </div>
        </div>
    );
}

export default function App() {
    return (
        <body className="min-h-screen bg-gray-900 text-gray-300 font-mono">
            <Header />
            <Content />
        </body>
    );
}
